import { Agent, } from '@types'

type UserManagementState = {
  agents: Array<Agent>
}

export const SET_AGENTS = 'reducers/userManagement/SET_AGENTS' as const
export const GET_AGENTS = 'reducers/userManagement/GET_AGENTS' as const
export const SEARCH_AGENTS = 'reducers/userManagement/SEARCH_AGENTS' as const

export const setAgents = (agents: Array<Agent>) => ({
  type: SET_AGENTS,
  agents,
})

export const getAgents = (includeEndOfWork?: boolean) => ({
  type: GET_AGENTS,
  includeEndOfWork,
})

export const searchAgents = (searchValue: string) => ({
  type: SEARCH_AGENTS,
  searchValue,
})

type AppStateAction =
  | ReturnType<typeof setAgents>
  | { type: 'persist/REHYDRATE' }

const initialState: UserManagementState = {
  agents: [],
}

const reducers = (state = initialState, action: AppStateAction) => {
  switch(action.type) {
    case SET_AGENTS:
      return Object.assign({}, state, {
        agents: action.agents,
      })
    default:
      return state
  }
}

export default reducers

import React, { useRef, } from 'react'
import { Scrollbars, } from 'react-custom-scrollbars'

interface Props extends ReactCustomScrollbars.ScrollbarProps {
  children: React.ReactNode
  onEndReached: () => void
}

function InfiniteScrollbar(props: Props) {
  const { children, onEndReached, ... rest } = props

  const preventEndHandler = useRef(false)
  const scrollHeightMemo = useRef(0)

  const onScrollUpdate = (values: ReactCustomScrollbars.positionValues) => {
    const { scrollTop, scrollHeight, clientHeight, } = values
    const pad = 50

    if(scrollHeightMemo.current !== scrollHeight) {
      scrollHeightMemo.current = scrollHeight
      preventEndHandler.current = false
    }

    const t = ((scrollTop + pad) / (scrollHeight - clientHeight))

    if(isFinite(t) && t > 1 && !preventEndHandler.current) {
      preventEndHandler.current = true

      onEndReached()
    }
  }

  return (
    <Scrollbars
      onUpdate={ onScrollUpdate }
      { ... rest }>
      { children }
    </Scrollbars>
  )
}

export default InfiniteScrollbar

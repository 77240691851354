import { all, } from 'redux-saga/effects'

import accountSagas from './account'
import userManagementSagas from './userManagement'
import notificationSagas from './notification'

export default function* rootSaga() {
  yield all([
    ... accountSagas,
    ... userManagementSagas,
    ... notificationSagas,
  ])
}

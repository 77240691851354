import styled from 'styled-components'
import { useSelector, useDispatch, } from 'react-redux'
import { useParams, useLocation, useNavigate, } from 'react-router-dom'
import React, { useRef, useMemo, useEffect, useCallback, } from 'react'

import {
  Header, AgentList, NotificationList,
  AgentListMap, DashboardUtils, AgentDetail,
  RemindingCreatedNotificationList,
} from '@systems'

import { RootState, getAgents, searchAgents, } from '@reducers'

import { Agent, } from '@types'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .map-container {
    display: flex;
    flex: 1;

    #map {
      width: 100%;
      flex: 1;
    }

    ${ AgentList }, ${ NotificationList }, ${ RemindingCreatedNotificationList } {
      position: absolute;
      width: 350px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      background-color: rgba(255, 255, 255, 0.85);
      align-self: center;
      border-radius: 5px;
      z-index: 100;
      border-radius: 4px;
      overflow: hidden;
    }

    ${ DashboardUtils } {
      position: absolute;
      width: 350px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      height: 50px;
      background-color: rgba(255, 255, 255, 0.85);
      align-self: center;
      border-radius: 5px;
      z-index: 100;
      border-radius: 4px;
      overflow: hidden;
      top: 100px;
      left: 40px;
    }

    ${ AgentDetail } {
      position: absolute;
      width: 300px;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      background-color: rgba(255, 255, 255, 0.85);
      align-self: center;
      border-radius: 5px;
      z-index: 100;
      border-radius: 4px;
      overflow: hidden;
      top: 160px;
      left: 420px;
    }

    ${ AgentList } {
      height: calc(95vh - 160px);
      top: 160px;
      left: 40px;
      padding-bottom: 65px;
    }

    ${ NotificationList } {
      height: calc(57vh - 160px);
      top: 100px;
      right: 40px;
    }

    ${ RemindingCreatedNotificationList } {
      height: calc(54vh - 190px);
      top: calc(55vh);
      right: 40px;
    }
  }
`

function MainControllMap() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { agentId: selectedAgentId, } = useParams()

  const agents = useSelector((state: RootState) => state.userManagement.agents)
  const includeEndOfWorkingAgents = useSelector((state: RootState) => state.appState.data.includeEndOfWorkingAgents)

  const map = useRef<naver.maps.Map>()

  const agentSearchValue = useMemo(
    () => {
      const urlParams = new URLSearchParams(location.search)
      return urlParams.get('search-agent')
    },
    [ location, ]
  )

  const fetchAgents = useCallback(
    () => {
      if(agentSearchValue) {
        dispatch(searchAgents(agentSearchValue))
      } else {
        dispatch(getAgents(includeEndOfWorkingAgents))
      }
    },
    [ agentSearchValue, includeEndOfWorkingAgents, ]
  )

  useEffect(
    () => {
      fetchAgents()

      const autoRefreshAgentsInterval = setInterval(fetchAgents, 10000)

      return () => {
        clearInterval(autoRefreshAgentsInterval)
      }
    },
    [ agentSearchValue, fetchAgents, ]
  )

  const onAgentSelect = useCallback(
    (agent: Agent) => () => {
      if(agent.id.toString() !== selectedAgentId) {
        navigate(`/agents/${ agent.id }`)
      }

      if(map.current) {
        map.current.setZoom(14, false)
        map.current.panTo(new naver.maps.LatLng(
          agent.agent_location.coord.latitude,
          agent.agent_location.coord.longitude
        ), { duration: 500, })
      }
    },
    [ selectedAgentId, ]
  )

  return (
    <Container>
      <Header />
      <div className='map-container'>
        <DashboardUtils />
        <AgentListMap
          agents={ agents }
          selectedAgentId={ selectedAgentId }
          onAgentSelect={ onAgentSelect }
          map={ map.current }
          setMap={ (newMap) => map.current = newMap }
        />
        <AgentList
          agents={ agents }
          selectedAgentId={ selectedAgentId }
          onAgentSelect={ onAgentSelect }
        />
        <NotificationList />
        <RemindingCreatedNotificationList />
        <AgentDetail agents={ agents } />
      </div>
    </Container>
  )
}

export default MainControllMap

import React from 'react'
import styled from 'styled-components'
import { enqueueSnackbar, } from 'notistack'

import { API_BASE_URL, } from '@globals'

interface Props {
  id: string | number
  linkType: 'dealer' | 'agent' | 'requesting' | 'phoneCall'
  children: React.ReactNode
}

const StyledA = styled.a`
  color: blue;
`

function AdminRelatedLink(props: Props) {
  const { id, linkType, children, } = props

  const saveToClipboard = async (text: string) => {
    /*
    try {
      await window.navigator.clipboard.writeText(text)

      enqueueSnackbar('복사되었습니다!', {
        autoHideDuration: 3000,
        variant: 'success',
      })
    } catch (error) {
    }
   */

    //window.open(`pcar-admin://main?url=${ text }`)
    window.open(text)
  }

  const phoneCall = (phoneNumber: string) => {
    if(window.confirm('해당 번호로 전화를 연결하시겠습니까?')) {
      // @ts-ignore
      if(window.electronAPI) {
        // @ts-ignore
        window.electronAPI.phonecall(phoneNumber)
      }
    }
  }

  const onLinkClick = () => {
    switch(linkType) {
      case 'agent':
        saveToClipboard(`${ API_BASE_URL }/admin/users/agent/${ id }/change`)
        break
      case 'dealer':
        saveToClipboard(`${ API_BASE_URL }/admin/users/dealer/${ id }/change`)
        break
      case 'requesting':
        saveToClipboard(`${ API_BASE_URL }/admin/requestings/requestinghistory/${ id }/change`)
        break
      case 'phoneCall':
        phoneCall(id.toString())
        break
    }
  }

  return (
    <StyledA onClick={ onLinkClick }>
      { children }
    </StyledA>
  )
}

export default AdminRelatedLink

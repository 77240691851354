import React from 'react'
import 'moment/locale/ko'
import { Provider, } from 'react-redux'
import ReactDOM from 'react-dom/client'
import { SnackbarProvider, } from 'notistack'
import { PersistGate, } from 'redux-persist/integration/react'

import { store, persistor, } from '@reducers'

import App from './App'
import reportWebVitals from './reportWebVitals'
import './index.css'

const root = ReactDOM.createRoot(
  document.getElementById('app') as HTMLElement
)

root.render(
  <Provider store={ store }>
    <PersistGate loading={ null } persistor={ persistor }>
      <SnackbarProvider maxSnack={ 5 }>
        <App />
      </SnackbarProvider>
    </PersistGate>
  </Provider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

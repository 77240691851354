import styled from 'styled-components'
import { useDispatch, } from 'react-redux'
import React, { useState, useCallback, } from 'react'
import { TextField, Button, FormControl, } from '@mui/material'

import { requestAuthenticationCode, signin, } from '@reducers'

import { useAppState, } from '@utils'

const Container = styled.div`
  align-self: center;
  width: 320px;
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  form {
    width: 100%;
  }

  .input {
    margin-bottom: 18px;
  }

  .title {
    margin-bottom: 32px;
  }

  .submit-button {
    height: 50px;
  }
`

function Signin() {
  const [ mobileNumber, setMobileNumber, ] = useState('')
  const [ authenticationCode, setAuthenticationCode, ] = useState('')
  const [ isWaitingAuthentication, setIsWaitingAuthentication, ] = useState(false)

  const dispatch = useDispatch()

  useAppState({
    'SUCCESS_REQUEST_AUTHENTICATION_CODE': () => {
      setIsWaitingAuthentication(true)
    },
  })

  const onAuthenticationClick = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()

      if(!isWaitingAuthentication) {
        dispatch(requestAuthenticationCode(mobileNumber))
      } else {
        dispatch(signin(mobileNumber, authenticationCode))
      }
    },
    [ isWaitingAuthentication, mobileNumber, authenticationCode, ]
  )

  return (
    <Container>
      <h2 className='title'>
        브릿카 상황실 대시보드 로그인
      </h2>
      <form onSubmit={ onAuthenticationClick }>
        <FormControl
          fullWidth={ true }>
          <TextField
            className='input'
            label='핸드폰 번호'
            disabled={ isWaitingAuthentication }
            fullWidth={ true }
            placeholder='핸드폰 번호를 입력해주세요'
            onChange={ e => setMobileNumber(e.target.value) }
          />
          {
            isWaitingAuthentication && (
              <TextField
                className='input'
                label='인증번호'
                fullWidth={ true }
                placeholder='인증번호를 입력해주세요'
                onChange={ e => setAuthenticationCode(e.target.value) }
              />
            )
          }
          <Button
            type='submit'
            className='submit-button'
            variant='contained'
            fullWidth={ true }>
            { !isWaitingAuthentication ? '인증번호 요청' : '로그인' }
          </Button>
        </FormControl>
      </form>
    </Container>
  )
}

export default Signin

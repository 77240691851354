import styled from 'styled-components'
import React, { useMemo, } from 'react'
import { useParams, } from 'react-router-dom'
import { Chat as ChatIcon, } from '@mui/icons-material'

import { AdminRelatedLink, } from '@components'

import { CHANNEL_TALK_CHANNEL_ID, } from '@globals'

import { Agent, } from '@types'

interface Props {
  agents: Array<Agent>
  className?: string
}

const Container = styled.div`
  padding: 12px 16px;

  h3 {
    color: #555555;
    font-weight: bold;
  }

  p {
    margin-top: 8px;
    color: #666666;
  }

  .currently-working-requesting-title {
    font-weight: bold;
  }

  .currently-working-requesting-info {
    padding: 10px 8px;

    p {
      line-height: 20px;
      font-size: 14px;

      &.without-margin {
        margin-top: 0;
      }
    }
  }

  .footer {
    margin-top: 8px;
    justify-content: flex-end;

    a {
      color: #666666;
    }
  }
`

function AgentDetail(props: Props) {
  const { agents, className, } = props
  const { agentId, } = useParams()

  const selectedAgent = useMemo(
    () => {
      if(!agentId) {
        return undefined
      }

      return agents.find(agent => agent.id.toString() === agentId)
    },
    [ agents, agentId, ]
  )

  const onChatClick = () => {
    if(selectedAgent) {
      const channelTalkSearchQuery = `{"and":[{"or":[{"key":"user.memberId","type":"string","operator":"$in","values":["${ selectedAgent.id }"]}]}]}`
      const encodedSearchQuery = btoa(channelTalkSearchQuery)

      window.open(
        `https://desk.channel.io/#/channels/${ CHANNEL_TALK_CHANNEL_ID }/contacts?query=${ encodedSearchQuery }&search=`
      )
    }
  }

  if(!selectedAgent) {
    return null
  }

  return (
    <Container className={ className }>
      <h3>
        { selectedAgent.name } 평카인&nbsp;
        (
          <AdminRelatedLink
            linkType='agent'
            id={ selectedAgent.id }>
            { selectedAgent.agent_profile.id }
          </AdminRelatedLink>
        )
      </h3>
      <p className='agent-id'>
        <AdminRelatedLink linkType='phoneCall' id={ selectedAgent.mobile_number }>{ selectedAgent.mobile_number }</AdminRelatedLink> ({ selectedAgent.agent_profile.level } 레벨)
      </p>
      <p className='agent-id'>
        희망 도착지: { selectedAgent.agent_location.desired_destination_address || '없음' }
      </p>
      <p className='currently-working-requesting-title'>
        현재 수행중인 오더 정보:
      </p>
      <div className='currently-working-requesting-info'>
        {
          !selectedAgent.currently_working_requesting ? (
            <p className='without-margin'>수행중인 오더 없음</p>
          ) : (
            <>
              <p className='without-margin'>
                오더 번호:&nbsp;
                <AdminRelatedLink
                  linkType='requesting'
                  id={ selectedAgent.currently_working_requesting.id }>
                  { selectedAgent.currently_working_requesting.id }
                </AdminRelatedLink>
              </p>
              <p>출발지: { selectedAgent.currently_working_requesting.source_address  }</p>
              <p>도착지: { selectedAgent.currently_working_requesting.destination_address  }</p>
            </>
          )
        }
      </div>
      <div className='footer row'>
        <a onClick={ onChatClick }>
          <ChatIcon />
        </a>
      </div>
    </Container>
  )
}

export default styled(AgentDetail)``

import styled from 'styled-components'
import React, { useMemo, useCallback, } from 'react'
import { useNavigate, useLocation, } from 'react-router-dom'

import { AgentSummary, InfiniteScrollbar, } from '@components'

import { Agent, } from '@types'

interface Props {
  agents: Array<Agent>
  onAgentSelect: (agent: Agent) => () => void
  className?: string
  selectedAgentId?: string
}

const Container = styled.div`
  .header {
    display: flex;
    align-items: center;
    height: 66px;
    background-color: ${ props => props.theme.colors.primary };
    padding: 0 15px;

    h2 {
      font-size: 21px;
      color: white;
    }
  }

  .agent-summary-list {
    .search-result-description-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 32px;

      button {
        width: 100px;
        height: 35px;
        margin-top: 16px;
      }
    }
  }
`

function AgentList(props: Props) {
  const { agents, className, onAgentSelect, selectedAgentId, } = props

  const location = useLocation()
  const navigate = useNavigate()

  const agentSearchValue = useMemo(
    () => {
      const urlParams = new URLSearchParams(location.search)
      return urlParams.get('search-agent')
    },
    [ location, ]
  )

  const onResetSearchClick = () => {
    navigate('/')
  }

  return (
    <Container className={ className }>
      <div className='header'>
        <h2>
          평가사 목록
        </h2>
      </div>
      <InfiniteScrollbar onEndReached={ () => null }>
        <div className='agent-summary-list'>
          {
            agents.map((agent, index) => {
              return (
                <AgentSummary
                  onClick={ onAgentSelect(agent) }
                  key={ `agent-summary-${ index }` }
                  agent={ agent }
                  style={{
                    ... (index === agents.length - 1 ? {
                      marginBottom: 0,
                      borderBottomWidth: 0,
                    } : {}),
                  }}
                />
              )
            })
          }
          {
            agentSearchValue && (
              <div className='search-result-description-container'>
                <p><b>{ agentSearchValue }</b>으로 검색한 평가사 목록입니다.</p>
                <button onClick={ onResetSearchClick }>검색 초기화</button>
              </div>
            )
          }
        </div>
      </InfiniteScrollbar>
    </Container>
  )
}

export default styled(AgentList)``

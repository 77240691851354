import update from 'immutability-helper'
import { useSelector, useDispatch, } from 'react-redux'
import { useMemo, useState, useEffect, useCallback, useLayoutEffect, } from 'react'

import { RootState, } from '@reducers'

import { TypePaginationResult, TypeStatePaginationParams, } from '@types'

interface UseAppStateAction {
  [key: string]: (params: any) => void
}

export function useAppState(actions?: UseAppStateAction) {
  const appState = useSelector(
    (state: RootState) => state.appState.state === 'RESETTING_STATE' ?
      null : state.appState.state
  )

  const params = useSelector((state: RootState) => state.appState.params)

  useLayoutEffect(
    () => {
      if(appState && actions && typeof actions[appState] === 'function') {
        actions[appState](params)
        //resetAppState()
      }
    },
    [ appState, params, ]
  )
}

export const useStatePagination = <R extends object>(params: TypeStatePaginationParams) => {
  const {
    lazy, successState, resultKey, deps, limit = 20, disabled,
    reduxAction, reduxActionParams, forwardRef, refreshStates,
  } = params

  const dispatch = useDispatch()

  const [ offset, setOffset, ] = useState(0)
  const [ isRefreshing, setIsRefreshing, ] = useState(false)
  const [ paginatableData, setPaginatableData, ] = useState<TypePaginationResult<R> | null>(null)

  const appState = useSelector((state: RootState) => state.appState.state)
  const appStateParams = useSelector((state: RootState) => state.appState.params)

  useEffect(
    () => {
      if(appState === successState) {
        if(offset === 0) {
          setPaginatableData(appStateParams)
        } else {
          const newPaginatableDatas = paginatableData![resultKey].concat(appStateParams[resultKey])

          setPaginatableData(
            Object.assign({}, appStateParams, {
              [resultKey]: newPaginatableDatas,
            })
          )
        }

        if(isRefreshing) {
          setIsRefreshing(false)
        }

        //resetAppState()
      }
    },
    [ appState, appStateParams, successState, resultKey, ]
  )

  const appendDatas = useCallback(
    (newDatas: Array<R>) => {
      if(paginatableData?.results && paginatableData.results.length > 0) {
        const newPaginatableDatas = update(paginatableData, {
          results: { $unshift: newDatas, },
        })

        const newOffset = offset + newDatas.length

        setPaginatableData(newPaginatableDatas)
        setOffset(newOffset)
      } else {
        const newPaginatableDatas = {
          pagination: {
            count: newDatas.length,
            next: '',
            previous: '',
          },
          results: newDatas,
        }

        const newOffset = newDatas.length

        setPaginatableData(newPaginatableDatas)
        setOffset(newOffset)
      }
    },
    [ paginatableData, offset, ]
  )

  const isInitialLoadDone = useMemo(
    () => paginatableData !== null,
    [ paginatableData, ]
  )

  const isMoreExists = useMemo(
    () => {
      return paginatableData?.pagination && paginatableData.pagination.next
    },
    [ paginatableData, ]
  )

  const loadMore = () => {
    if(!isInitialLoadDone) {
      return
    }

    // @TODO
    const newOffset = offset + 1

    if(paginatableData!.pagination.count > newOffset) {
      dispatch(
        // @ts-ignore
        reduxAction(
          {
            limit,
            offset: newOffset,
          },
          reduxActionParams
        )
      )

      setOffset(newOffset)
    } else {
      return null
    }
  }

  const loadInitial = () => {
    dispatch(
      // @ts-ignore
      reduxAction(
        {
          offset: 0,
          limit,
        },
        reduxActionParams
      )
    )

    if(offset > 0) {
      setOffset(0)
    }
  }

  const refresh = () => {
    setIsRefreshing(true)

    dispatch(
      // @ts-ignore
      reduxAction(
        {
          offset: 0,
          limit,
        },
        reduxActionParams
      )
    )

    setOffset(0)
  }

  /*
  useImperativeHandle(forwardRef, () => ({
    refresh,
  }))
  */

  useEffect(
    () => {
      if(!lazy && !disabled) {
        loadInitial()
      }
    },
    deps || []
  )

  useAppState(
    refreshStates?.reduce(
      (result, state: string) => ({
        ... result,
        [state]: refresh,
      }),
      {}
    )
  )

  return {
    data: paginatableData,
    loadMore,
    loadInitial,
    refresh,
    isInitialLoadDone,
    isMoreExists,
    isRefreshing,
    currentOffset: offset,
    appendDatas,
  }
}

import styled from 'styled-components'
import React, { useState, } from 'react'
import { TextField, } from '@mui/material'
import { useDispatch, } from 'react-redux'
import { useParams, useLocation, useNavigate, } from 'react-router-dom'
import { Search as SearchIcon, } from '@mui/icons-material'

import { logout, } from '@reducers'

const Container = styled.header`
  display: flex;
  justify-content: space-between;
  height: 80px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  background-color: white;
  z-index: 100;
  padding-left: 40px;
  padding-right: 30px;
  align-items: center;

  h1 {
    color: ${ props => props.theme.colors.primary };
  }

  .search-button {
    color: ${ props => props.theme.colors.primary };
  }

  .logout-button {
    color: #666666;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
  }

  .agent-search-input {
    margin-right: 30px;

    .search-field {
      margin-right: 8px;
    }
  }
`

function Header() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [ agentSearchValue, setAgentSearchValue, ] = useState('')

  const onAgentSearchClick = () => {
    const urlParams = new URLSearchParams(location.search)

    if(urlParams.get('search-agent') !== agentSearchValue) {
      if(!agentSearchValue) {
        navigate('/')
      } else {
        navigate(`/?search-agent=${ agentSearchValue }`)
      }
    }
  }

  const onLogoutClick = () => {
    if(confirm('로그아웃 하시겠습니까?')) {
      dispatch(logout())
    }
  }

  return (
    <Container>
      <h1>
        브릿카 상황실 대시보드
      </h1>
      <div className='row'>
        <div className='agent-search-input row'>
          <TextField
            className='search-field'
            size='small'
            placeholder='평가사 이름 검색'
            value={ agentSearchValue }
            onChange={ e => setAgentSearchValue(e.target.value) }
            inputProps={{ type: 'search', }}
            onKeyDown={
              e => {
                if(e.key === 'Enter') {
                  onAgentSearchClick()
                }
              }
            }
          />
          <a className='search-button' onClick={ onAgentSearchClick }>
            <SearchIcon />
          </a>
        </div>
        <a className='logout-button' onClick={ onLogoutClick }>
          로그아웃
        </a>
      </div>
    </Container>
  )
}

export default Header

import moment from 'moment'
import styled from 'styled-components'
import { useDispatch, } from 'react-redux'
import { enqueueSnackbar, } from 'notistack'
import React, { useState, useCallback, } from 'react'
import { Notifications as NotificationIcon, } from '@mui/icons-material'

import { readNotification, } from '@reducers'

import { useAppState, } from '@utils'

import { TypeNotification, } from '@types'

import AdminRelatedLink from '../AdminRelatedLink'

interface Props {
  activity: TypeNotification
}

const Container = styled.div`
  cursor: pointer;
  transition: 0.3s background-color ease;

  > div {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(221, 221, 221);
    padding: 13px 0;
    margin: 0 12px;

    .row {
      justify-content: space-between;

      .new-notification {
        color: green;
      }
    }
  }

  span {
    color: rgb(85, 85, 85);
  }

  .activity-subject {
    font-size: 20px;
    font-weight: bold;
    line-height: 29px;
  }

  .activity-information-container {
    span {
      font-size: 15px;
      line-height: 24px;
    }
  }

  .activity-createdat {
    font-size: 13px;
    margin-top: 2px;
    font-weight: 600;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.08);
  }
`

function ActivitySummary(props: Props) {
  const { activity, } = props

  const dispatch = useDispatch()

  const [ isRead, setRead, ] = useState(activity.is_read)

  useAppState({
    'SUCCESS_READ_NOTIFICATION': (id: string) => {
      if(id === activity.id.toString()) {
        setRead(true)

        enqueueSnackbar('읽음처리 되었습니다!', {
          autoHideDuration: 3000,
          variant: 'success',
        })
      }
    },
  })

  const renderTitle = useCallback(
    () => {
      switch(activity.subject) {
        case 'CREATE_REQUESTING':
          return '오더 발주'
        case 'CREATE_BULK_REQUESTING':
          return '다량오더 발주'
        case 'START_EVALUATION':
          return '평카(검수) 시작'
        case 'FINISH_EVALUATION':
          return '평카(검수) 완료'
        case 'CLIENT_PURCHASE_DECISION':
          return '의뢰인 매입 결정'
        case 'DEPARTURE_DELIVERY':
          return '탁송 시작'
        case 'ARRIVAL_DELIVERY':
          return '탁송 완료'
        case 'CLIENT_CONFIRM_ARRIVAL_DELIVERY':
          return '의뢰인 탁송 인도 확인'
      }
    },
    [ activity, ]
  )

  const getRequestingType = useCallback(
    () => {
      if(activity.requesting_history === undefined) {
        return ''
      }

      switch(activity.requesting_history.type) {
        case 'EVALUATION_DELIVERY':
          return '평카탁송'
        case 'INSPECTION_DELIVERY':
          return '검수탁송'
        case 'ONLY_DELIVERY':
          return '탁송만'
      }
    },
    [ activity, ]
  )

  const renderSubInformations = useCallback(
    () => {
      if(activity.subject === 'CREATE_BULK_REQUESTING' ||
          activity.subject === 'CREATE_REQUESTING') {
        return (
          <>
            <span>
              의뢰인: { activity.actor.name }
            </span>
          </>
        )
      } else if(activity.subject === 'START_EVALUATION' ||
                activity.subject === 'FINISH_EVALUATION') {
        return (
          <>
            {
              activity.requesting_history !== undefined ? (
                <>
                  <span>
                    해당 의뢰번호:&nbsp;
                    <AdminRelatedLink
                      linkType='requesting'
                      id={ activity.requesting_history.id }>
                      { activity.requesting_history.id }
                    </AdminRelatedLink>
                    &nbsp;
                    ({ getRequestingType() })
                  </span>
                  <span>
                    담당 평가사:&nbsp;
                    <AdminRelatedLink
                      linkType='agent'
                      id={ activity.actor.id }>
                      { activity.actor.agent_profile.id }
                    </AdminRelatedLink>
                    &nbsp;
                    ({ activity.actor.name })
                  </span>
                  <span>
                    의뢰인:&nbsp;
                    <AdminRelatedLink
                      linkType='dealer'
                      id={ activity.user.id }>
                      { activity.user.id }
                    </AdminRelatedLink>
                    &nbsp;
                    ({ activity.user.name })
                  </span>
                </>
              ) : null
            }
          </>
        )
      } else if(activity.subject === 'DEPARTURE_DELIVERY' ||
                activity.subject === 'ARRIVAL_DELIVERY') {
        return (
          <>
            {
              activity.requesting_history !== undefined ? (
                <>
                  <span>
                    해당 의뢰번호:&nbsp;
                    <AdminRelatedLink
                      linkType='requesting'
                      id={ activity.requesting_history.id }>
                      { activity.requesting_history.id }
                    </AdminRelatedLink>&nbsp;
                    ({ getRequestingType() })
                  </span>
                  <span>
                    담당기사:&nbsp;
                    <AdminRelatedLink
                      linkType='agent'
                      id={ activity.actor.id }>
                      { activity.actor.agent_profile.id }
                      </AdminRelatedLink>&nbsp;
                    ({ activity.actor.name })
                  </span>
                  <span>
                    의뢰인:&nbsp;
                    <AdminRelatedLink
                      linkType='dealer'
                      id={ activity.user.id }>
                      { activity.user.id }
                    </AdminRelatedLink>&nbsp;
                    ({ activity.user.name })
                  </span>
                </>
              ) : null
            }
          </>
        )
      } else if(activity.subject === 'CLIENT_CONFIRM_ARRIVAL_DELIVERY') {
        return (
          <>
            {
              activity.requesting_history !== undefined ? (
                <span>
                  해당 의뢰번호:&nbsp;
                  <AdminRelatedLink
                    linkType='requesting'
                    id={ activity.requesting_history.id }>
                    { activity.requesting_history.id }
                  </AdminRelatedLink>&nbsp;
                  ({ getRequestingType() })
                </span>
              ) : null
            }
          </>
        )
      }
    },
    [ activity, ]
  )

  const renderDate = useCallback(
    () => {
      if(activity.subject === 'REMINDING_CREATED_REQUESTING') {
        if(activity.requesting_history) {
          return (
            <span className='activity-createdat'>
              { moment(activity.requesting_history.created_at).format('YYYY-MM-DD HH:mm:ss') } (<b>{ moment(activity.requesting_history.created_at).fromNow() }</b>)
            </span>
          )
        }
      } else if(activity.subject === 'CREATE_REQUESTING' || activity.subject === 'CREATE_BULK_REQUESTING') {
        return (
          <span className='activity-createdat'>
            { moment(activity.created_at).format('YYYY-MM-DD HH:mm:ss') } (<b>{ moment(activity.created_at).fromNow() }</b>)
          </span>
        )
      }
    },
    [ activity, ]
  )

  /*
        <span className='activity-subject'>
          { renderTitle() }
        </span>
 */

  if(!activity.requesting_history) {
    return (
      null
    )
  }

  return (
    <Container onClick={ () => !isRead && dispatch(readNotification(activity.id.toString())) }>
      <div>
        <div className='row'>
          <div className='activity-information-container'>
            의뢰번호 (
              <AdminRelatedLink
                id={ activity.requesting_history.id }
                linkType='requesting'>
                { activity.requesting_history.id }
              </AdminRelatedLink>
            )
          </div>
          {
            !isRead && (
              <NotificationIcon className='new-notification' />
            )
          }
        </div>
        <div className='activity-information-container'>
          { renderSubInformations() }
        </div>
        <span className='activity-createdat'>
          { renderDate() }
        </span>
      </div>
    </Container>
  )
}

export default ActivitySummary

interface AccountData {
}

type AccountState = {
  token: string | null
  refreshToken: string | null
  data: AccountData
}

export const APPLY_AUTH_TOKEN = 'reducers/account/APPLY_AUTH_TOKEN' as const
export const REFRESH_TOKEN = 'reducers/account/REFRESH_TOKEN' as const
export const SAVE_USER_DATA = 'reducers/account/SAVE_USER_DATA' as const

export const REQUEST_AUTHENTICATION_CODE = 'reducers/account/REQUEST_AUTHENTICATION_CODE' as const
export const SIGNIN = 'reducers/account/SIGNIN' as const
export const LOGOUT = 'reducers/account/LOGOUT' as const
export const GET_USER_PROFILE = 'reducers/account/GET_USER_PROFILE' as const

export const applyAuthToken = (
  token: null | string,
  refreshToken?: string
) => ({
  type: APPLY_AUTH_TOKEN,
  token,
  refreshToken,
})

export const refreshToken = (channel: any) => ({ type: REFRESH_TOKEN,
  channel,
})

export const saveUserData = (key: keyof AccountData, value: any, isAppend = false) => ({
  type: SAVE_USER_DATA,
  key,
  value,
  isAppend,
})

export const signin = (mobileNumber: string, authenticationCode: string) => ({
  type: SIGNIN,
  mobileNumber,
  authenticationCode,
})

export const requestAuthenticationCode = (mobileNumber: string) => ({
  type: REQUEST_AUTHENTICATION_CODE,
  mobileNumber,
})

export const getUserProfile = () => ({
  type: GET_USER_PROFILE,
})


export const logout = () => ({
  type: LOGOUT,
})

const initialState: AccountState = {
  token: null,
  refreshToken: null,
  data: {},
}

type AccountAction =
  | ReturnType<typeof applyAuthToken>
  | ReturnType<typeof saveUserData>

const reducers = (state = initialState, action: AccountAction) => {
  switch(action.type) {
    case APPLY_AUTH_TOKEN:
      return Object.assign({}, state, {
        token: action.token,
        refreshToken: action.refreshToken,
        ... (action.userProfile !== undefined ? {
          data: {
            ... state.data,
            profile: action.userProfile,
          },
        } : {
          data: state.data,
        }),
      })
    case SAVE_USER_DATA:
      return Object.assign({}, state, {
        data: {
          ... state.data,
          ... (action.isAppend ? {
            [action.key]: {
              // @ts-ignore
              ... state.data[action.key],
              ... action.value,
            },
          } : {
            [action.key]: action.value,
          }),
        },
      })
    default:
      return state
  }
}

export default reducers

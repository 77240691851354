import styled from 'styled-components'
import { useDispatch, } from 'react-redux'
import React, { useEffect, useCallback, } from 'react'

import { ActivitySummary, InfiniteScrollbar, } from '@components'

import { useAppState, useStatePagination, } from '@utils'

import { getRemindingCreatedRequestingActivities, getRemindingCreatedRequestingActivitiesIdAfter, } from '@reducers'

import { TypeNotification, } from '@types'

interface Props {
  className?: string
}

const Container = styled.div`
  .header {
    display: flex;
    align-items: center;
    height: 66px;
    background-color: rgb(87, 87, 87);
    padding: 0 15px;

    h2 {
      font-size: 21px;
      color: white;
    }
  }
`

function RemindingCreatedNotificationList(props: Props) {
  const { className, } = props

  const dispatch = useDispatch()

  const { data, appendDatas, } = useStatePagination<TypeNotification>({
    reduxAction: getRemindingCreatedRequestingActivities,
    resultKey: 'results',
    successState: 'SUCCESS_GET_REMINDING_CREATED_REQUESTING_ACTIVITIES',
  })

  useAppState({
    'SUCCESS_GET_REMINDING_CREATED_REQUESTING_ACTIVITIES_ID_AFTER': (newDatas: Array<TypeNotification>) => {
      appendDatas(newDatas)

      for(const notification of newDatas) {
        /*
        if(notification.subject === 'CREATE_REQUESTING') {
          // @ts-ignore
          const audio = new Audio(require('@assets/sounds/new_requesting.mp3'))
          audio.play()
        }
       */
      }
    },
  })

  const getLatestActivities = useCallback(
    () => {
      dispatch(getRemindingCreatedRequestingActivitiesIdAfter(data?.results?.[0]?.id || 1))
    },
    [ data, ]
  )

  useEffect(
    () => {
      const getActivitiesInterval = setInterval(getLatestActivities, 10000)

      return () => clearInterval(getActivitiesInterval)
    },
    [ getLatestActivities, ]
  )

  return (
    <Container className={ className }>
      <div className='header'>
        <h2>
          미배차 오더 리마인딩
        </h2>
      </div>
      <InfiniteScrollbar onEndReached={ () => null }>
        <div style={{ paddingBottom: 80, }}>
          {
            data?.results?.map((activity, index) => {
              return (
                <ActivitySummary
                  key={ `activities-${ index }` }
                  activity={ activity }
                />
              )
            })
          }
        </div>
      </InfiniteScrollbar>
    </Container>
  )
}

export default styled(RemindingCreatedNotificationList)``

import { enqueueSnackbar, } from 'notistack'
import axios, { AxiosResponse, } from 'axios'
import { put, call, takeLatest, } from 'redux-saga/effects'

import {
  GET_AGENTS,
  SEARCH_AGENTS,
  setAgents,
} from '@reducers/userManagement'

import { authorizedAxios, } from '@utils'

import { urls, } from '@globals'

import { Agent, } from '@types'

import { withAppState, } from './utils'

function* getAgents({ includeEndOfWork, }: {
  type: string
  includeEndOfWork?: boolean
}) {
  const response: AxiosResponse<Array<Agent>> = yield call(authorizedAxios, urls.users.AGENTS, {
    method: 'GET',
    params: {
      include_end_of_work: includeEndOfWork,
    },
  })

  yield put(setAgents(response.data))
}

function* searchAgents({ searchValue, }: {
  type: string
  searchValue: string
}) {
  const response: AxiosResponse<Array<Agent>> = yield call(authorizedAxios, urls.users.AGENTS, {
    method: 'GET',
    params: {
      search_value: searchValue,
    },
  })

  yield put(setAgents(response.data))
}

const userManagementSagas = [
  takeLatest(GET_AGENTS, withAppState('GET_AGENTS', getAgents)),
  takeLatest(SEARCH_AGENTS, withAppState('SEARCH_AGENTS', searchAgents)),
]

export default userManagementSagas

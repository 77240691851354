import React from 'react'
import moment from 'moment'
import styled from 'styled-components'

import { AdminRelatedLink, } from '@components'

import { Agent, } from '@types'

interface Props {
  agent: Agent
  style?: any
  onClick?: () => void
}

const Container = styled.div`
  transition: 0.3s background-color ease;

  > .row {
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: rgb(221, 221, 221);
    padding: 13px 0;
    margin: 0 12px;
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .agent-profile-image {
    width: 80px;
    height: 80px;
    border-radius: 6px;
    object-fit: contain;
  }

  .agent-information-container {
    margin-left: 8px;

    .agent-name {
      font-size: 20px;
      font-weight: bold;
      color: rgb(85, 85, 85);
      margin-right: 8px;
    }

    .agent-level, .agent-id {
      font-size: 15px;
      color: rgb(117, 117, 117);
      margin-right: 5px;
    }

    .agent-common {
      font-size: 15px;
      color: rgb(85, 85, 85);
      margin-top: 5px;

      &.location-updated-at {
        font-size: 12px;
      }
    }
  }

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.08);
  }
`

function AgentSummary(props: Props) {
  const { agent, style, onClick, } = props

  const renderWorkingStatus = () => {
    if(agent.is_working) {
      return `${ moment(agent.agent_location.updated_at).fromNow() } 업데이트`
    } else {
      return <b>업무종료</b>
    }
  }

  return (
    <Container style={ style } onClick={ onClick }>
      <div className='row'>
        <img
          src={ agent.agent_profile.profile_image }
          className='agent-profile-image'
        />
        <div className='agent-information-container'>
          <div className='row'>
            <span className='agent-name'>{ agent.name }</span>
            <span className='agent-level'>{ agent.agent_profile.level }레벨</span>
            <span className='agent-id'>(사번: { agent.agent_profile.id })</span>
          </div>
          <div className='row'>
            <span className='agent-common' style={{ marginTop: 8, }}>
              <AdminRelatedLink linkType='phoneCall' id={ agent.mobile_number }>{ agent.mobile_number }</AdminRelatedLink> ({ renderWorkingStatus() })
            </span>
          </div>
          <div className='row'>
            <span className='agent-common'>
              희망 도착지: { agent.agent_location.desired_destination_address || '없음' }
            </span>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default AgentSummary

import { enqueueSnackbar, } from 'notistack'
import axios, { AxiosResponse, } from 'axios'
import { put, call, select, takeLatest, takeEvery, } from 'redux-saga/effects'

import {
  REQUEST_AUTHENTICATION_CODE,
  SIGNIN,
  LOGOUT,
  applyAuthToken,
  REFRESH_TOKEN,
} from '@reducers/account'

import { urls, } from '@globals'

import { withAppState, refreshAccessToken, } from './utils'

function* requestAuthenticationCode({ mobileNumber, }: {
  type: string
  mobileNumber: string
}) {
  yield call(axios, urls.users.REQUEST_AUTHENTICATION_CODE, {
    method: 'POST',
    data: {
      purpose: 'control_room_signin',
      mobile_number: mobileNumber,
    },
  })
}

function* signin({ mobileNumber, authenticationCode, }: {
  type: string
  mobileNumber: string
  authenticationCode: string
}) {
  const response: AxiosResponse<{
    access_token: string
    refresh_token: string
  }> = yield call(axios, urls.users.SIGNIN, {
    method: 'POST',
    data: {
      role: 'control_room',
      mobile_number: mobileNumber,
      authentication_code: authenticationCode,
    },
  })

  enqueueSnackbar('로그인 되었습니다!', {
    autoHideDuration: 5000,
    variant: 'success',
  })

  const { access_token, refresh_token, } = response.data

  yield put(applyAuthToken(access_token, refresh_token))
}

function* logout() {
  yield put(applyAuthToken(null, ''))

  enqueueSnackbar('로그아웃 되었습니다.', {
    autoHideDuration: 3000,
    variant: 'success',
  })
}

function* refreshToken({ channel, }: {
  channel: any
}) {
  const { token, refreshToken, } = yield select(state => state.account)

  if(!refreshToken) {
    throw new Error('NOT_AVAILABLE_REFRESH_TOKEN')
  }

  yield call(
    refreshAccessToken,
    token,
    refreshToken
  )

  if(channel) {
    yield put(channel, { status: 'FINISH', })
  }
}

const accountSagas = [
  takeLatest(REQUEST_AUTHENTICATION_CODE, withAppState('REQUEST_AUTHENTICATION_CODE', requestAuthenticationCode)),
  takeLatest(SIGNIN, withAppState('SIGNIN', signin)),
  takeLatest(LOGOUT, withAppState('LOGOUT', logout)),
  takeEvery(REFRESH_TOKEN, withAppState('REFRESH_TOKEN', refreshToken)),
]

export default accountSagas

import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'
import { persistStore, persistReducer, } from 'redux-persist'
import { createStore, applyMiddleware, combineReducers, } from 'redux'

import rootSaga from '@sagas'

import { IS_DEBUG, } from '@globals'

import appStateReducer, { setAppState, } from './appState'
import accountReducer from './account'
import userManagementReducer from './userManagement'
import notificationReducer from './notification'

const reducer = combineReducers({
  appState: appStateReducer,
  account: accountReducer,
  userManagement: userManagementReducer,
  notification: notificationReducer,
})

const reducerPersistKey = IS_DEBUG ?
  'pcar-control-room-debug-persist-store' : 'pcar-control-room-production-persist-store'

const persistConfig = {
  key: reducerPersistKey,
  storage,
  blacklist: [ 'appState', ],
}

const enhancedReducer = persistReducer(persistConfig, reducer)

const sagaMiddleware = createSagaMiddleware()

const middleware = applyMiddleware(sagaMiddleware)

const store = createStore(enhancedReducer, middleware)

const resetAppState = () => {
  store.dispatch(setAppState('RESETTING_STATE'))
}

const persistor = persistStore(store)

sagaMiddleware.run(rootSaga)

export {
  store,
  reducer,
  persistor,
  resetAppState,
}

export type RootState = ReturnType<typeof reducer>

export * from './appState'
export * from './account'
export * from './userManagement'
export * from './notification'

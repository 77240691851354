import React from 'react'
import { useSelector, } from 'react-redux'
import { ThemeProvider, } from 'styled-components'
import { BrowserRouter, Route, Routes, } from 'react-router-dom'

import { Signin, MainDashboard, } from '@pages'

import { RootState, } from '@reducers'

import { THEMES, GlobalStyle, } from '@styles'

function App() {
  const token = useSelector((state: RootState) => state.account.token)

  return (
    <ThemeProvider theme={ THEMES }>
      <GlobalStyle />
      <BrowserRouter>
      </BrowserRouter>
      {
        token ? (
          <BrowserRouter>
            <Routes>
              <Route path='/' element={ <MainDashboard /> } />
              <Route path='/agents/:agentId' element={ <MainDashboard /> } />
            </Routes>
          </BrowserRouter>
        ) : (
          <Signin />
        )
      }
    </ThemeProvider>
  )
}

export default App

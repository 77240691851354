import { TypeNotification, TypePaginationParams, } from '@types'

type NotificationState = {
  agents: Array<TypeNotification>
}

export const SET_ACTIVITIES = 'reducers/notification/SET_ACTIVITIES' as const

export const READ_NOTIFICATION = 'reducers/notification/READ_NOTIFICATION' as const

export const GET_CREATED_REQUESTING_ACTIVITIES = 'reducers/notification/GET_CREATED_REQUESTING_ACTIVITIES' as const
export const GET_CREATED_REQUESTING_ACTIVITIES_ID_AFTER = 'reducers/notification/GET_CREATED_REQUESTING_ACTIVITIES_ID_AFTER' as const

export const GET_REMINDING_CREATED_REQUESTING_ACTIVITIES = 'reducers/notification/GET_REMINDING_CREATED_REQUESTING_ACTIVITIES' as const
export const GET_REMINDING_CREATED_REQUESTING_ACTIVITIES_ID_AFTER = 'reducers/notification/GET_REMINDING_CREATED_REQUESTING_ACTIVITIES_ID_AFTER' as const

export const setActivities = (activities: Array<Notification>) => ({
  type: SET_ACTIVITIES,
  activities,
})

export const readNotification = (id: string) => ({
  type: READ_NOTIFICATION,
  id,
})

export const getCreatedRequestingActivites = (pagination: TypePaginationParams) => ({
  type: GET_CREATED_REQUESTING_ACTIVITIES,
  pagination,
})

export const getCreatedRequestingActivitesIdAfter = (idAfter: number) => ({
  type: GET_CREATED_REQUESTING_ACTIVITIES_ID_AFTER,
  idAfter,
})

export const getRemindingCreatedRequestingActivities = (pagination: TypePaginationParams) => ({
  type: GET_REMINDING_CREATED_REQUESTING_ACTIVITIES,
  pagination,
})

export const getRemindingCreatedRequestingActivitiesIdAfter = (idAfter: number) => ({
  type: GET_REMINDING_CREATED_REQUESTING_ACTIVITIES_ID_AFTER,
  idAfter,
})

type AppStateAction =
  | ReturnType<typeof setActivities>
  | { type: 'persist/REHYDRATE' }

const initialState: NotificationState = {
  agents: [],
}

const reducers = (state = initialState, action: AppStateAction) => {
  switch(action.type) {
    case SET_ACTIVITIES:
      return Object.assign({}, state, {
        agents: action.activities,
      })
    default:
      return state
  }
}

export default reducers

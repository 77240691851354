import { IS_DEBUG, } from './constants'

export let API_BASE_URL = ''

if(IS_DEBUG) {
  API_BASE_URL = 'http://api.dev.home.d405.io:8000'
} else {
  API_BASE_URL = 'https://api.bridcar.com'
}

const USER_BASE_URL = `${ API_BASE_URL }/v1/users`
const NOTIFICATION_BASE_URL = `${ API_BASE_URL }/v1/notifications`

const users = {
  REFRESH_TOKEN: `${ USER_BASE_URL }/token/refresh`,
  SIGNIN: `${ USER_BASE_URL }/signin`,
  REQUEST_AUTHENTICATION_CODE: `${ USER_BASE_URL }/authentications/sms`,
  AGENTS: `${ USER_BASE_URL }/agents`,
}

const notifications = {
  ACTIVITY_LIST: `${ NOTIFICATION_BASE_URL }/activities`,
  ACTIVITY_DETAIL: `${ NOTIFICATION_BASE_URL }/activities/%s`,
}

const urls = {
  users,
  notifications,
}

export {
  urls,
}

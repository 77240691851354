import { normalize, } from 'styled-normalize'
import { createGlobalStyle, } from 'styled-components'

export const THEMES = {
  colors: {
    primary: '#4351f9',
  },
}

export const GlobalStyle = createGlobalStyle`
  ${ normalize };

  .map-agent-marker {
    width: 50px;
    height: 25px;
    border-radius: 6px;
    background-color: rgba(128, 128, 128, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);

    &.working {
      background-color: rgba(128, 81, 249, 0.6);

      &:after {
        border-color: rgba(67, 81, 249, 0.6) transparent transparent transparent;
      }
    }

    &:after {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      top: 100%;
      right: 50%;
      transform: translateX(50%);
      border: 6px solid;
      border-color: rgba(128, 128, 128, 0.6) transparent transparent transparent;
      z-index: -1;
    }

    &.selected, &:hover {
      background-color: rgba(67, 81, 249, 0.9);

      &:after {
        z-index: 99;
        border-color: rgba(67, 81, 249, 0.9) transparent transparent transparent;
      }
    }

    span {
      font-size: 13px;
      color: white;
      font-weight: bold;
    }
  }

  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
`

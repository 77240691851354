type AppState = {
  state: string | null
  params: any
  loading: boolean
  isFixLoading: boolean
  data: AppStateData
}

interface AppStateData {
  includeEndOfWorkingAgents: boolean
}

export const SET_APP_STATE = 'reducers/appState/SET_APP_STATE' as const
export const SAVE_APP_DATA = 'reducers/appState/SAVE_APP_DATA' as const
export const GET_CONSTANTS = 'reducers/appState/GET_CONSTANTS' as const

export const setAppState = (state: string, params?: any, loading?: boolean, isFixLoading?: boolean) => ({
  type: SET_APP_STATE,
  state,
  params,
  loading,
  isFixLoading,
})

export const saveAppData = (key: keyof AppStateData, data: any, isAppend?: boolean) => ({
  type: SAVE_APP_DATA,
  key,
  data,
  isAppend,
})

export const getConstants = () => ({
  type: GET_CONSTANTS,
})

const initialState: AppState = {
  state: null,
  params: null,
  loading: false,
  isFixLoading: false,
  data: {
    includeEndOfWorkingAgents: true,
  },
}

type AppStateAction =
  | ReturnType<typeof setAppState>
  | ReturnType<typeof saveAppData>
  | { type: 'persist/REHYDRATE' }

const reducers = (state = initialState, action: AppStateAction) => {
  switch(action.type) {
    case SET_APP_STATE: {
      let loading = state.loading

      if(!action.loading &&
        (!state.isFixLoading || action.isFixLoading === false)) {
        loading = false
      } else if(action.loading === true) {
        loading = true
      }

      return Object.assign({}, state, {
        state: action.state,
        params: action.params,
        loading,
        isFixLoading: state.isFixLoading ?
          (action.isFixLoading === undefined ?
           state.isFixLoading : action.isFixLoading) : action.isFixLoading,
      })
    }
    case SAVE_APP_DATA:
      return Object.assign({}, state, {
        data: {
          ... state.data,
          [action.key]: (action.isAppend && typeof action.data === 'object') ? {
            // @ts-ignore
            ... state.data[action.key],
            ... action.data,
          } : action.data,
        },
      })
    default:
      return state
  }
}

export default reducers

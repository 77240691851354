import { channel, } from 'redux-saga'
import { put, take, call, select, } from 'redux-saga/effects'
import axios, { AxiosError, AxiosRequestConfig, } from 'axios'

import { refreshToken, } from '@reducers'

export type CustomAxiosErrorType = 'RESPONSE' | 'REQUEST' | 'ETC'

export type CustomAxiosError = {
  isCustomAxiosError: boolean
  type: CustomAxiosErrorType
  response?: object
  request?: object
  message?: string
  statusCode?: number
  statusText?: string
}

export function generateCustomAxiosError(error: AxiosError): CustomAxiosError | null {
  if(!(error.request || error.response)) {
    return null
  }

  const result: CustomAxiosError = {
    isCustomAxiosError: true,
    type: 'ETC',
  }

  result.request = error.request

  if(error.response) {
    result.type = 'RESPONSE'

    result.response = error.response
    result.statusCode = error.response.status
    result.statusText = error.response.statusText
  } else if(error.request) {
    result.type = 'REQUEST'
  }

  result.message = error.message

  return result
}

// @ts-ignore
export function* authorizedAxios(url: string, config: AxiosRequestConfig = {}, customToken?: string) {
  const { token, } = yield select(state => state.account)

  try {
    // @ts-ignore
    return yield call(axios, url, {
      ... config,
      headers: {
        ... (config.headers || {}),
        ... ((customToken || token) ? {
          Authorization: `Bearer ${ customToken || token }`,
        } : {}),
      },
    })
  } catch (error: any) {
    if(!error.response && error.message === 'Network Error') {
      throw new Error(error.message)
    }

    if(error.response) {
      if(error.response?.status === 401 ||
         error.response?.status === 422) {
        // @ts-ignore
        const chan = yield call(channel)

        yield put(refreshToken(chan))
        yield take(chan)
      } else {
        throw error
      }
    }
  }
}

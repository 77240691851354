import classNames from 'classnames'
import { useNavigate, } from 'react-router-dom'
import React, { useRef, useEffect, useCallback, } from 'react'

import { Agent, } from '@types'

interface Props {
  agents: Array<Agent>
  onAgentSelect: (agent: Agent) => () => void
  selectedAgentId?: string
  map?: naver.maps.Map
  setMap: (map: naver.maps.Map) => void
}

const MAP_INITIAL_LOCATION = new naver.maps.LatLng(36.593462851084155, 127.97110607545955)

function AgentListMap(props: Props) {
  const { map, setMap, agents, selectedAgentId, onAgentSelect, } = props

  const mapMarkers = useRef<Array<naver.maps.Marker>>([])

  useEffect(
    () => {
      const initMap = () => {
        setMap(
          new naver.maps.Map('map', {
            center: MAP_INITIAL_LOCATION,
            zoom: 8,
          })
        )
      }

      initMap()
    },
    []
  )

  useEffect(
    () => {
      if(!map) {
        return
      }

      if(!selectedAgentId) {
        map.setZoom(8, false)
        map.panTo(MAP_INITIAL_LOCATION, { duration: 200, })
      }
    },
    [ map, selectedAgentId, ]
  )

  const hideMarker = useCallback(
    (marker: naver.maps.Marker) => {
      marker.setMap(null)
    },
    []
  )

  const clearAllMarkers = useCallback(
    () => {
      if(mapMarkers.current?.length > 0) {
        for(const existMarker of mapMarkers.current) {
          hideMarker(existMarker)
        }

        mapMarkers.current = []
      }
    },
    []
  )

  useEffect(
    () => {
      if(!map) {
        return
      }

      clearAllMarkers()

      const newMarkers = []

      // @ts-ignore
      for(const [ index, agent, ] of agents.entries()) {
        const isFocusing = agent.id.toString() === selectedAgentId
        const className = classNames('map-agent-marker', {
          working: agent.is_working,
          selected: isFocusing,
        })

        const newMarker = new naver.maps.Marker({
          map: map,
          position: {
            x: agent.agent_location.coord.longitude,
            y: agent.agent_location.coord.latitude,
          },
          icon: {
            content: `<div class='${ className }'><span>${ agent.name }</span></div>`,
            anchor: new naver.maps.Point(25, 31),
          },
          zIndex: index + 10 + (isFocusing ? 1000 : 0),
        })

        newMarker.addListener('click', onAgentSelect(agent))
        newMarkers.push(newMarker)
      }

      mapMarkers.current = newMarkers
    },
    [ map, agents, selectedAgentId, ]
  )

  return (
    <div id='map' />
  )
}

export default AgentListMap

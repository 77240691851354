import axios, { AxiosError, AxiosResponse, } from 'axios'
import { enqueueSnackbar, } from 'notistack'
import { put, call, take, delay, spawn, select, } from 'redux-saga/effects'

import { setAppState, applyAuthToken, } from '@reducers'

import { generateCustomAxiosError, } from '@utils'

import { urls, } from '@globals'

interface WithAppStateParams {
  usingLoad?: boolean
  withDebounce?: boolean
  showAlertWhenMessageExists?: boolean
  hideLoadingIcon?: boolean
}

function generateAppStateSetter (action: string, usingLoad = false) {
  return {
    onStart: function* (debounce?: boolean) {
      if(debounce) {
        yield delay(200)
      }

      yield put(setAppState(`START_${ action }`, null, usingLoad, undefined))
    },
    onSuccess: function* (params?: any) {
      yield put(setAppState(`SUCCESS_${ action }`, params, usingLoad ? false : undefined))
    },
    onFail: function* (params?: AxiosError | any, showAlertWhenMessageExists = true) {
      const errorState = `FAILED_${ action }`

      const customAxiosError = generateCustomAxiosError((params as AxiosError))

      if(customAxiosError) {
        enqueueSnackbar(`네트워크 에러가 발생했습니다.`, {
          variant: 'error',
        })
        try {
          /*
          if(customAxiosError.statusCode === 400) {

          }
          */
        } catch (error) {}
      } else {
        try {
        } catch (error) {}
      }

      yield put(setAppState(errorState, customAxiosError || params.message, usingLoad ? false : undefined))
    },
  }
}

export function withAppState(stateText: string, sagaGenerator: any, params: WithAppStateParams = {}) {
  const {
    usingLoad,
    withDebounce,
    showAlertWhenMessageExists,
    hideLoadingIcon,
  } = params

  const { onStart, onFail, onSuccess, } = generateAppStateSetter(stateText, usingLoad)

  return function* (... args: any[]) {
    yield onStart(withDebounce)

    try {
      // @ts-ignore
      const result = yield call(sagaGenerator, ... args)

      yield onSuccess(result)
    } catch (error) {
      yield onFail(error, showAlertWhenMessageExists)
    }
  }
}

export let isRefreshingToken = false

export function* refreshAccessToken(
  token: string,
  refreshToken: string
  /*
  resumeActionParams: {
    url: string
    config: any
  }
  */
): any {
  if(isRefreshingToken) {
    return
  }

  isRefreshingToken = true

  try {
    const response: AxiosResponse<{
      access_token: string
      refresh_token: string
    }> =
      yield call(axios, urls.users.REFRESH_TOKEN, {
        method: 'POST',
        data: { refresh_token: refreshToken, },
        headers: {
          Authorization: `Bearer ${ token }`,
        },
      })

    const refreshData = response.data

    yield put(applyAuthToken(refreshData.access_token, refreshData.refresh_token))

    isRefreshingToken = false
  } catch (error: any) {
    isRefreshingToken = false

    if(!error.response) {
      yield put(applyAuthToken(null))
    } else if(error.response?.status === 401) {
      yield put(applyAuthToken(null))
    }
  }
}

import { sprintf, } from 'sprintf-js'
import { AxiosResponse, } from 'axios'
import { enqueueSnackbar, } from 'notistack'
import { put, call, takeLatest, } from 'redux-saga/effects'

import {
  READ_NOTIFICATION,
  GET_CREATED_REQUESTING_ACTIVITIES,
  GET_CREATED_REQUESTING_ACTIVITIES_ID_AFTER,
  GET_REMINDING_CREATED_REQUESTING_ACTIVITIES,
  GET_REMINDING_CREATED_REQUESTING_ACTIVITIES_ID_AFTER,
} from '@reducers/notification'

import { authorizedAxios, } from '@utils'

import { urls, } from '@globals'

import { TypePagination, TypePaginationResult, } from '@types'

import { withAppState, } from './utils'

function* readNotification({ id, }: {
  type: string
  id: string
}) {
  yield call(authorizedAxios, sprintf(urls.notifications.ACTIVITY_DETAIL, id), {
    method: 'POST',
  })

  return id
}

function* getCreatedRequestingActivites({ pagination, }: {
  type: string
  pagination: TypePagination
}) {
  const response: AxiosResponse<TypePaginationResult<Notification>> =
    yield call(authorizedAxios, urls.notifications.ACTIVITY_LIST, {
      params: {
        ... pagination,
        subject__in: 'CREATE_REQUESTING,CREATE_BULK_REQUESTING',
      },
    })

  return response.data
}

function* getCreatedRequestingActivitesIdAfter({ idAfter, }: {
  type: string
  idAfter: number,
}) {
  const response: AxiosResponse<Array<Notification>> =
    yield call(authorizedAxios, urls.notifications.ACTIVITY_LIST, {
      params: {
        id_after: idAfter,
        subject__in: 'CREATE_REQUESTING,CREATE_BULK_REQUESTING',
      },
    })

  return response.data
}

function* getRemindingCreatedRequestingActivities ({ pagination, }: {
  type: string
  pagination: TypePagination
}) {
  const response: AxiosResponse<TypePaginationResult<Notification>> =
    yield call(authorizedAxios, urls.notifications.ACTIVITY_LIST, {
      params: {
        ... pagination,
        subject: 'REMINDING_CREATED_REQUESTING',
      },
    })

  return response.data
}

function* getRemindingCreatedRequestingActivitiesIdAfter({ idAfter, }: {
  type: string
  idAfter: number,
}) {
  const response: AxiosResponse<Array<Notification>> =
    yield call(authorizedAxios, urls.notifications.ACTIVITY_LIST, {
      params: {
        id_after: idAfter,
        subject: 'REMINDING_CREATED_REQUESTING',
      },
    })

  return response.data
}

const notificationSagas = [
  takeLatest(READ_NOTIFICATION, withAppState('READ_NOTIFICATION', readNotification)),
  takeLatest(GET_CREATED_REQUESTING_ACTIVITIES, withAppState('GET_CREATED_REQUESTING_ACTIVITIES', getCreatedRequestingActivites)),
  takeLatest(GET_CREATED_REQUESTING_ACTIVITIES_ID_AFTER, withAppState('GET_CREATED_REQUESTING_ACTIVITIES_ID_AFTER', getCreatedRequestingActivitesIdAfter)),
  takeLatest(GET_REMINDING_CREATED_REQUESTING_ACTIVITIES, withAppState('GET_REMINDING_CREATED_REQUESTING_ACTIVITIES', getRemindingCreatedRequestingActivities)),
  takeLatest(GET_REMINDING_CREATED_REQUESTING_ACTIVITIES_ID_AFTER, withAppState('GET_REMINDING_CREATED_REQUESTING_ACTIVITIES_ID_AFTER', getRemindingCreatedRequestingActivitiesIdAfter)),
]

export default notificationSagas

import React from 'react'
import styled from 'styled-components'
import { Switch, } from '@mui/material'
import { useDispatch, useSelector, } from 'react-redux'

import { RootState, saveAppData, } from '@reducers'

interface Props {
  className?: string
}

const Container = styled.div`
  display: flex;
  padding: 0 12px;
  align-items: center;

  .row {
    flex: 1;
    justify-content: space-between;

    p {
      color: #333333;
    }
  }
`

function DashboardUtils(props: Props) {
  const { className, } = props

  const dispatch = useDispatch()

  const includeEndOfWorkingAgents = useSelector((state: RootState) => state.appState.data.includeEndOfWorkingAgents)

  const onChangeValue = (e: React.ChangeEvent<HTMLInputElement>, value: boolean) => {
    dispatch(saveAppData('includeEndOfWorkingAgents', value))
  }

  return (
    <Container className={ className }>
      <div className='row'>
        <p>
          업무종료한 평카인 같이 보기여부
        </p>
        <Switch
          checked={ includeEndOfWorkingAgents }
          onChange={ onChangeValue }
        />
      </div>
    </Container>
  )
}

export default styled(DashboardUtils)``
